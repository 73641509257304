<svelte:options
	customElement={{
		tag: 'mm-frontpage-video',
		props: {
			video_asset: {
				type: 'Object'
			}
		}
	}}
/>

<script>
	import { get } from 'svelte/store';

	let { video_asset } = $props();

	let source = $state(false);
	let href = $state('');

	async function getMedia(id) {
		let res = await fetch(`https://svp.vg.no/svp/api/v1/ap/assets/${id}?appName=test`);
		let json = await res.json();

		if (!json || json.statusCode == 401) {
			throw 'error';
		} else {
			return json;
		}
	}

	$effect(() => {
		if (video_asset.id && video_asset.id.toString().length == 6) {
			getMedia(video_asset.id)
				.then((data) => {
					source = data;
				})
				.catch((error) => {
					console.error(error);
				});
		}
	});

	function getParentLink(node) {
		// Base case: if we've reached the top of the document
		if (!node) {
			return null;
		}

		// Check if current node has the attribute
		if (node.nodeType === Node.ELEMENT_NODE && node.hasAttribute('data-article-url')) {
			return node.getAttribute('data-article-url');
		}

		// If we're in a shadow root, jump to the host
		if (node.nodeType === Node.DOCUMENT_FRAGMENT_NODE || node instanceof ShadowRoot) {
			return getParentLink(node.host);
		}

		// Get the root node (could be document or shadowRoot)
		const rootNode = node.getRootNode();

		// If we're in a shadow root, jump to the host
		if (rootNode instanceof ShadowRoot) {
			// First check if any parent within this shadow DOM has the attribute
			const parent = node.parentNode;
			if (parent && parent !== rootNode) {
				const result = getParentLink(parent);
				if (result) return result;
			}

			// If not found in shadow DOM, continue from the host
			return getParentLink(rootNode.host);
		}

		// Regular DOM traversal
		return getParentLink(node.parentNode);
	}

	function getHref(node) {
		href = getParentLink(node);
	}
</script>

<!-- <div class="mm-video-wrap" {...restProps}> -->
{#await source then asset}
	<a {href} use:getHref>
		{#if asset && asset?.streamUrls?.mp4}
			<video
				style:object-position="{video_asset.hotspot?.x ?? 50}% {video_asset.hotspot?.y ?? 50}%"
				src={asset.streamUrls.mp4 + '#t=0.1'}
				playsinline
				loop
				autoplay
				muted
			></video>
		{/if}
	</a>
{/await}

<style lang="scss">
	a {
		text-decoration: none;
	}
	video {
		object-fit: cover;
		object-position: center;
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
</style>
